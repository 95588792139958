import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../_http.methods";

export function acceptOffer(offerObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: offerObj,
      ResourceUrl: "v1/customers/accept-offers-fortiva",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function rejectOffer(offerObj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: offerObj,
        ResourceUrl: "v1/customers/reject-offers-fortiva",
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function GetRating(customerId, applicationId, approvalId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: approvalId,
        ResourceUrl: "v1/magix/get-RatingCode?customerId="+customerId+"&applicationid="+applicationId+"&approvalCode="+approvalId,
        isMagix:true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  export function saveApprovalAndRatingCode(obj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: obj,
        ResourceUrl: "v1/customers/SaveApprovalandRatingCode?Rating_Code="+obj.ratingCode+"&approvalCode="+obj.approvalCode+"&customerId="+obj.customerId+"&applicationid="+obj.applicationid+"&store_id="+obj.store_id,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  // export function getExistingDaniels(magixId) {
  //   return function (dispatch) {
  //     const data = {
  //       MethodType: HttpMethods.Post(),
  //       Resource: magixId,
  //       ResourceUrl: "v1/customers/get-existingDaniels?magixId="+magixId,
  //     };
  //     return ApiWrapper.LoadData(data)
  //       .then((response) => {
  //         if (response) {
  //           return response;
  //         } else {
  //           return response;
  //         }
  //       })
  //       .catch((error) => {
  //         throw error;
  //       });
  //   };
  // }

  export function getGridCustomerData(customerId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: customerId,
        ResourceUrl: "v1/customers/GetGridCustomerData?customer_id="+customerId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getOfferStatusOrFaxbackInfo(cusID, appId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: cusID, appId,
        ResourceUrl: "v1/magix/CreditDirector_GetData_New?customerId="+cusID+"&applicationid="+appId,
        isMagix: true
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function postDanielsAcceptedStatus(obj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: obj,
        ResourceUrl: "v1/customers/DanielOfferAccepted?customer_id="+obj.cusID+"&application_id="+obj.appId+"&offer_accepted="+obj.status,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }